import React, { useState, useEffect } from 'react';
import './GameBoard.css';

function GameBoard(props) {
  const [cells, setCells] = useState(Array(9).fill(''));
  const [xTurn, setXTurn] = useState(props.player === 'X');
  const [gameStatus, setGameStatus] = useState('');

  useEffect(() => {
    checkGameStatus();
  }, [cells]);

  function handleClick(index) {
    if (cells[index] || gameStatus) return;

    const currentClass = xTurn ? 'X' : 'O';
    const newCells = [...cells];
    newCells[index] = currentClass;

    setCells(newCells);
    setXTurn(!xTurn);
  }

  function checkGameStatus() {
    const winningCombinations = [      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < winningCombinations.length; i++) {
      const [a, b, c] = winningCombinations[i];
      if (cells[a] && cells[a] === cells[b] && cells[a] === cells[c]) {
        setGameStatus(`${cells[a] =='X' ? '⭐️' : '🌎'} 勝出！耶穌愛你❤️`);
        return;
      }
    }

    if (isDraw()) {
      setGameStatus("打和!耶穌愛你❤️");
      return;
    }
  }

  function isDraw() {
    return !cells.includes('');
  }

  function renderCell(index) {

    let showContent = index+1;
    if(cells[index]=="X" ){
      showContent ="⭐️";
    }else if(cells[index]=="O" ){
      showContent ="🌎";
    }

    return (
      <div className="cell" onClick={() => handleClick(index)}>
        {showContent}
      </div>
    );
  }

  return (
    <div className="game-board">
      {renderCell(0)}
      {renderCell(1)}
      {renderCell(2)}
      {renderCell(3)}
      {renderCell(4)}
      {renderCell(5)}
      {renderCell(6)}
      {renderCell(7)}
      {renderCell(8)}
      {gameStatus && <div className="game-status">{gameStatus}</div>}
    </div>
  );
}

export default GameBoard;
