import React, { useState } from 'react';
import StartGameModal from './StartGameModal';
import GameBoard from './GameBoard';

function Game() {
  const [player, setPlayer] = useState(null);
  const [showModal, setShowModal] = useState(true);

  function handlePlayerSelect(selectedPlayer) {
    setPlayer(selectedPlayer);
    setShowModal(false);
  }

  function handleNewGame() {
    setPlayer(null);
    setShowModal(true);
  }

  function renderModal() {
    if (showModal) {
      return <StartGameModal onPlayerSelect={handlePlayerSelect} />;
    }
    return null;
  }

  function renderGame() {
    if (player) {
      return (
        <div>
          <h1>井字過三關遊戲</h1>
          <GameBoard player={player} />
          <br/>
          <button onClick={handleNewGame}>重新開始</button>
        </div>
      );
    }
    return null;
  }

  return (
    <div>
      {renderModal()}
      {renderGame()}
    </div>
  );
}

export default Game;
